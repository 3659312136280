
.info {
  margin-top: 50px;
}

.info img {
  width: 250px;
  height: 250px;
}

@media (max-width: 708px) {
  .info {
    margin-top: 15px;
  }

  .info img {
    width: 150px;
    height: 150px;
  }
}

.infoBTN {
  color: #6E8F7F !important;
  border: 1px solid #6E8F7F !important;
} 

.infoBTN:hover {
  color: white !important;
  background-color: #6E8F7F !important;
  border: 1px solid #6E8F7F !important;
}

/* .cardBG {
  background-color: #6E8F7F;
} */