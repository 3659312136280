.App {
  position: relative;
  text-align: center;
  min-height: 100vh !important;
}

body {
  background-color: #FBFBFB !important;
  font-family: 'Cormorant Garamond', 'Antic Didone', 'Lustria', Calibri, serif, 'Trebuchet MS', sans-serif !important;
}

.mainContent {
  padding-bottom: 300px;
}

.myNavbar {
  /* background-color: #8FA4B5 !important; */
  font-size: larger;
}
.nav-heading {
  font-size: 21px;
}


.container {
  /* border: 1px solid blue; */
}

.captionBG {
  background-color: #ffffff73;
  /* padding: 50px; */
}

.carouselImages {
  /* max-height: 45vh; */
  /* border: 1px solid red; */
}
.carouselImages img {
  /* opacity: 0.5; */
  /* height: 90% !important; */
}

p {
  font-size: 22px;
}

.cards {
  margin: 30px !important;
  /* background-color: #F5F5F5 !important; */
  /* background-color: #6E8F7F !important; */
  transition: box-shadow .3s;
  cursor: pointer;
  padding-bottom: 2%;
  min-width: 150px !important;
  /* background: rgb(166,191,175);
  background: linear-gradient(90deg, rgba(166,191,175,1) 0%, rgba(211,246,229,1) 99%); */
}

/* .cards:hover  {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
} */

.myDiv {
  position: sticky;
  position: -webkit-sticky;
  top: 45%;
  right: 1%;
  margin-bottom: 10px;
  cursor: pointer;
  color: gray;
}

.sticky {
  position: sticky !important;
  position: -webkit-sticky;
  top: 20%;
  left: 3%;
}

.scrollBtn {
  position: absolute;
  bottom: 10%;
  left: 49%;
  cursor: pointer;
  opacity: 0.5;
}

header {
  position: relative;
  background-color: black;
  height: 75vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
  /* border: 1px solid blue;; */
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header .container {
  position: relative;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 1;
}

.myFooter {
  margin-top: 50px;
  position: absolute !important;
  bottom: 0;
  width: 100%;
}

.nav-link {
  color: #cecece;
}
.nav-link:hover {
  color: whitesmoke;
}

@media (max-width: 708px) {
  .mainContent {
    padding-bottom: 500px;
  }
  header h1 {
    font-size: 2rem;
  }
}
